<template>
  <div
    class="input-parameter-dialog"
  >
    <v-card
      class="dialog-card"
    >
      <div class="dialog-header">
        <v-card-title class="dialog-header-title">
          {{ targetData.title }}
        </v-card-title>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click="closeDialog"
        />
      </div>

      <v-text-field
        variant="outlined"
        :placeholder="`${targetData.title}を検索`"
        hide-details
        prepend-inner-icon="mdi-magnify"
        bg-color="#19212c"
        class="search-input"
        v-model="searchQuery"
      />

      <v-tabs
        class="tabs"
        fixed-tabs
        hide-slider
        v-model="currentTab"
      >
        <v-tab
          :value="category"
          class="tabs-content"
          v-for="category in categories"
          :key="category"
        >
          {{ TabsTitle[category] }}
        </v-tab>
      </v-tabs>

      <div
        v-if="_.isEmpty(targetData.categorizedImages)"
        class="notification"
      >
        Coming Soon...
      </div>

      <v-window
        v-else
        class="window-container"
        v-model="currentTab"
      >
        <v-window-item
          :value="category"
          class="images"
          v-for="category in categories"
          :key="category"
        >
          <v-card
            class="image-card"
            v-for="image in filteredImages()"
            :key="image.en"
            @click="handleImageClick(image)"
          >
            <v-img
              :src="image.src"
              :alt="image.en"
              height="126"
              cover
            />
            <v-card-title
              class="image-card-title"
            >
              {{ image.ja }}
            </v-card-title>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script lang='ts' setup>
import _ from 'lodash'
import { inputParameterEnum, inputParameterTagEnum, styleInputParameterEnum, styleInputParameters } from '~/constants/input-parameter'
import type { inputParameterDataType } from '~/interfaces'

const { id } = defineProps<{
  id: string
}>()

const all = 'all'

type Tabs = inputParameterTagEnum | typeof all

const TabsTitle: Record<Tabs, string> = {
  [all]: 'All',
  [inputParameterTagEnum.style]: 'スタイル',
  [inputParameterTagEnum.color]: 'カラー',
  [inputParameterTagEnum.material]: 'マテリアル',
  [inputParameterTagEnum.finish]: '仕上げ',
  [inputParameterTagEnum.sketch]: 'スケッチ',
}

const dialogStore = useDialogStore()
const { prompt, updatePromptProperty } = usePromptStore()
const searchQuery = ref('')
const currentTab = ref<Tabs>(all)
const categories = computed(() => [all as Tabs].concat(Object.values(inputParameterTagEnum).filter(tab =>
  isInterior(prompt[inputParameterEnum.structureType].en)
    ? tab !== inputParameterTagEnum.finish
    : tab !== inputParameterTagEnum.material),
))

const targetData = computed(() => {
  const { title, data } = isInterior(prompt[inputParameterEnum.structureType].en)
    ? styleInputParameters[styleInputParameterEnum.interior]
    : styleInputParameters[styleInputParameterEnum.exterior]
  const categorizedImages = Object.entries(data).reduce(
    (acc: inputParameterDataType[], cur) => {
      const [category, data] = cur
      if (currentTab.value === all) {
        acc.push(...data)
      } else if (currentTab.value === category) {
        acc.push(...data)
      }
      return acc
    },
    [],
  )

  return ({
    title,
    data,
    categorizedImages,
  })
})

const closeDialog = () => {
  dialogStore.closeDialog(id)
}

const filteredImages = () => {
  const searchRegex = searchQuery.value
    ? new RegExp(searchQuery.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
    : null

  const filtered = targetData.value.categorizedImages.filter(imageInfo =>
    !searchRegex || searchRegex.test(imageInfo.en) || searchRegex.test(imageInfo.ja),
  )

  return filtered
}

const handleImageClick = (image: inputParameterDataType) => {
  updatePromptProperty(inputParameterEnum.styleType, image)
  closeDialog()
}
</script>

<style lang='scss' scoped>
$--v-field-input-padding-top: 0;

.input-parameter-dialog {
  padding: 8px;

  .dialog-card {
    padding: 24px;
    overflow-y: auto;
    margin: auto;
    width: 90vw;
    height: 90vh;
    max-width: 948px;
    max-height: 822px;
    background-color: rgb(var(--v-theme-primary));
    display: flex;
    flex-direction: column;
    gap: 24px;

    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dialog-header-title {
        font-size: 16px;
        font-weight: 700;
        padding: 0;
      }
    }
  }

  .notification {
    text-align: center;
  }

  .window-container {
    max-height: calc(100% - 216px);
    overflow-y: scroll;

    .images {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 8px;

      @include media(sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      .image-card {
        border-radius: 4px;
      }
    }
  }

  .search-input {
    max-height: 40px;
  }

  .tabs {
    background-color: #19212c;

    .tabs-content {
      border-radius: 4px;

      &.v-tab-item--selected {
        background-color: rgb(var(--v-theme-surface));
        color: rgb(var(--v-theme-primary));
      }

      &:hover {
        background-color: #272e39;
        color: rgb(var(--v-theme-surface));
      }
    }
  }
}

.v-card {
  transition: transform 0.2s;

  .v-card-title {
    background-color: rgb(var(--v-theme-primary));
    text-align: center;
    font-size: 10px;
  }
}
</style>
